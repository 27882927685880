import React, { useState } from 'react';
import cn from 'classnames';
import { Tooltip } from 'antd';
import { TooltipPropsWithTitle } from 'antd/lib/tooltip';

import AbsoluteLoader from 'src/Framework/Controls/AbsoluteLoader';
import { pickTextColorBasedOnBgColorSimple } from 'src/Framework/util/helps';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

import { Container, Main, Icon, Badge } from './styled';
import LongPress from '../LongPress';

const defaultSize = 32;
const defaultIconScale = 1.7;

export const ICONS_LIST = {
  check2: require('src/Framework/Common/Svg/check2.svg').default,
  add: require('src/Framework/Common/Svg/add.svg').default,
  delete: require('src/Framework/Common/Svg/delete.svg').default,
  attachment: require('src/Framework/Common/Svg/attachment.svg').default,
  back: require('src/Framework/Common/Svg/back.svg').default,
  calendarPlus: require('src/Framework/Common/Svg/calendarPlus.svg').default,
  Close: require('src/Framework/Common/Svg/Close.svg').default,
  edit: require('src/Framework/Common/Svg/edit.svg').default,
  favorites: require('src/Framework/Common/Svg/favorites.svg').default,
  reservation: require('src/Framework/Common/Svg/reservation.svg').default,
  sortArrow: require('src/Framework/Common/Svg/sort-arrow.svg').default,
  sortIcon: require('src/Framework/Common/Svg/sort-icon.svg').default,
  week5: require('src/Framework/Common/Svg/week5.svg').default,
  week7: require('src/Framework/Common/Svg/week7.svg').default,
  emptyCalendar: require('src/Framework/Common/Svg/empty-calendar.svg').default,
  legend: require('src/Framework/Common/Svg/legend.svg').default,
  calendarAdd: require('src/Framework/Common/Svg/calendar-add.svg').default,
  calendarBlock: require('src/Framework/Common/Svg/calendar-block.svg').default,
  calendarReservation:
    require('src/Framework/Common/Svg/calendar-reservation.svg').default,
  flagDisable: require('src/Framework/Common/Svg/flag-disable.svg').default,
  flagEnable: require('src/Framework/Common/Svg/flag-enable.svg').default,
  messageRead: require('src/Framework/Common/Svg/message-read.svg').default,
  messageUnread: require('src/Framework/Common/Svg/message-unread.svg').default,
  filter: require('src/Framework/Common/Svg/filter.svg').default,
  secureMessages: require('src/Framework/Common/Svg/secure-messages.svg')
    .default,
  newMessage: require('src/Framework/Common/Svg/new-message.svg').default,
  share: require('src/Framework/Common/Svg/messaging/share.svg').default,
  forward: require('src/Framework/Common/Svg/messaging/arrow-right.svg')
    .default,
  print: require('src/Framework/Common/Svg/messaging/print.svg').default,
  help: require('src/Framework/Common/Svg/help.svg').default,
  user: require('src/Framework/Common/Svg/user.svg').default,
  thinClose: require('src/Framework/Common/Svg/thin-close.svg').default,
  arrowUp: require('src/Framework/Common/Svg/arrow-up.svg').default,
  arrowDown: require('src/Framework/Common/Svg/arrow-down.svg').default,
  check: require('src/Framework/Common/Svg/check.svg').default,
  paper: require('src/Framework/Common/Svg/messaging/paper.svg').default,
  document: require('src/Framework/Common/Svg/document.svg').default,
  checkCircle: require('src/Framework/Common/Svg/check-circle.svg').default,
  deleteFilled: require('src/Framework/Common/Svg/delete-filled.svg').default,
  thinArrow: require('src/Framework/Common/Svg/thin-arrow.svg').default,
  noteLink: require('src/Framework/Common/Svg/note-link.svg').default,
  dragControl: require('src/Framework/Common/Svg/drag-control.svg').default,
  warning: require('src/Framework/Common/Svg/warning.svg').default,
  folder: require('src/Framework/Common/Svg/folder.svg').default,
  history: require('src/Framework/Common/Svg/history.svg').default,
  settings: require('src/Framework/Common/Svg/settings.svg').default,
  user2: require('src/Framework/Common/Svg/user2.svg').default,
  usersGroup: require('src/Framework/Common/Svg/users-group.svg').default,
  editTask: require('src/Framework/Common/Svg/edit-task.svg').default,
  receipt: require('src/Framework/Common/Svg/receipt.svg').default,
  folderLink: require('src/Framework/Common/Svg/folder-link.svg').default,
  calendarCheck: require('src/Framework/Common/Svg/calendar-check.svg').default,
  calendarClose: require('src/Framework/Common/Svg/calendar-close.svg').default,
  cardFront: require('src/Framework/Common/Svg/card-front.svg').default,
  cardBack: require('src/Framework/Common/Svg/card-back.svg').default,
  group: require('src/Framework/Common/Svg/group.svg').default,
  checkAll: require('src/Framework/Common/Svg/check-all.svg').default,
  log: require('src/Framework/Common/Svg/log.svg').default,
  statusInProgress: require('src/Framework/Common/Svg/status-in-progress.svg')
    .default,
  statusNew: require('src/Framework/Common/Svg/status-new.svg').default,
  statusCompleted: require('src/Framework/Common/Svg/status-completed.svg')
    .default,
  statusFailed: require('src/Framework/Common/Svg/status-failed.svg').default,
  arrowNext: require('src/Framework/Common/Svg/arrow-next.svg').default,
  error2: require('src/Framework/Common/Svg/error2.svg').default,
  log2: require('src/Framework/Common/Svg/log2.svg').default,
  clone: require('src/Framework/Common/Svg/clone.svg').default,
  sort: require('src/Framework/Common/Svg/sort.svg').default,
  paperPlane: require('src/Framework/Common/Svg/paper-plane.svg').default,
  restricted: require('src/Framework/Common/Svg/restricted.svg').default,
  sign: require('src/Framework/Common/Svg/sign.svg').default,
  lock: require('src/Framework/Common/Svg/lock.svg').default,
  eye: require('src/Framework/Common/Svg/eye.svg').default,
  closeCircle: require('src/Framework/Common/Svg/close-circle.svg').default,
  eye2: require('src/Framework/Common/Svg/eye2.svg').default,
  eye3: require('src/Framework/Common/Svg/eye3.svg').default,
  search: require('src/Framework/Common/Svg/search.svg').default,
  appointmentType: require('src/Framework/Common/Svg/appointment-type.svg')
    .default,
  error3: require('src/Framework/Common/Svg/error3.svg').default,
  activityLog: require('src/Framework/Common/Svg/activity-log.svg').default,
  drag: require('src/Framework/Common/Svg/drag.svg').default,
  addCircle: require('src/Framework/Common/Svg/add-circle.svg').default,
  waitingListCheck: require('src/Framework/Common/Svg/waiting-list-check.svg')
    .default,
  threeDots: require('src/Framework/Common/Svg/three-dots.svg').default,
  sortAscending: require('src/Framework/Common/Svg/sort-ascending.svg').default,
  sortDescending: require('src/Framework/Common/Svg/sort-descending.svg')
    .default,
  groupList: require('src/Framework/Common/Svg/group-list.svg').default,
  deleteInMove: require('src/Framework/Common/Svg/delete-in-move.svg').default,
  settings3: require('src/Framework/Common/Svg/settings3.svg').default,
  upload: require('src/Framework/Common/Svg/upload.svg').default,
  refresh: require('src/Framework/Common/Svg/refresh.svg').default,
  family: require('src/Framework/Common/Svg/family.svg').default,
  groupAppt: require('src/Framework/Common/Svg/groupAppt.svg').default,
  filledCircle: require('src/Framework/Common/Svg/filled-circle.svg').default,
  filledArrowNext: require('src/Framework/Common/Svg/filled-arrow-next.svg')
    .default,
  filledEmptyCircle: require('src/Framework/Common/Svg/filled-empty-circle.svg')
    .default,
  filledArrowNextBlue:
    require('src/Framework/Common/Svg/filled-arrow-next-blue.svg').default,
  filledEmptyCircleBlue:
    require('src/Framework/Common/Svg/filled-empty-circle-blue.svg').default,
  help2: require('src/Framework/Common/Svg/help2.svg').default,
  comment: require('src/Framework/Common/Svg/comment.svg').default,
  todo: require('src/Framework/Common/Svg/todo.svg').default,
  todos: require('src/Framework/Common/Svg/Todos.svg').default,
  inProgressForms: require('src/Framework/Common/Svg/in-progress-forms.svg')
    .default,
  apptNote: require('src/Framework/Common/Svg/appt-note.svg').default,
  minus: require('src/Framework/Common/Svg/minus.svg').default,
  late: require('src/Framework/Common/Svg/late.svg').default,
  arrived: require('src/Framework/Common/Svg/arrived.svg').default,
  ready: require('src/Framework/Common/Svg/ready.svg').default,
  admitted: require('src/Framework/Common/Svg/admited.svg').default,
  letterL: require('src/Framework/Common/Svg/letter-L.svg').default,
  gender: require('src/Framework/Common/Svg/gender.svg').default,
  filterUsers: require('src/Framework/Common/Svg/filter-users.svg').default,
  distribution: require('src/Framework/Common/Svg/distribution.svg').default,
  noteAttachment: require('src/Framework/Common/Svg/note-attachment.svg')
    .default,
  softSign: require('src/Framework/Common/Svg/soft-sign.svg').default,
  filledError: require('src/Framework/Common/Svg/filled-error.svg').default,
  warningFilled: require('src/Framework/Common/Svg/warning-filled.svg').default,
  lastAccessed: require('src/Framework/Common/Svg/last-accessed.svg').default,
  zoomIcon: require('src/Framework/Common/Svg/zoom-icon.svg').default,
  addUser: require('src/Framework/Common/Svg/add-user.svg').default,
  camera: require('src/Framework/Common/Svg/camera.svg').default,
  cancelled: require('src/Framework/Common/Svg/cancelled.svg').default,
  signAppt: require('src/Framework/Common/Svg/sign-appt.svg').default,
  append: require('src/Framework/Common/Svg/append.svg').default,
  userComment: require('src/Framework/Common/Svg/user-comment.svg').default,
  link: require('src/Framework/Common/Svg/link.svg').default,
  userDownArrow: require('src/Framework/Common/Svg/user-down-arrow.svg')
    .default,
  event: require('src/Framework/Common/Svg/event.svg').default,
  filePdf: require('src/Framework/Common/Svg/file-pdf.svg').default,
  fileCommon: require('src/Framework/Common/Svg/file-common.svg').default,
  removeCircle: require('src/Framework/Common/Svg/remove-circle.svg').default,
  scanner: require('src/Framework/Common/Svg/scanner.svg').default,
  publicBuildingBlock:
    require('src/Framework/Common/Svg/publicBuildingBlock.svg').default,
  draftBuildingBlock: require('src/Framework/Common/Svg/draftBuildingBlock.svg')
    .default,
  header: require('src/Framework/Common/Svg/header.svg').default,
  headerDraft: require('src/Framework/Common/Svg/headerDraft.svg').default,
  footer: require('src/Framework/Common/Svg/footer.svg').default,
  footerDraft: require('src/Framework/Common/Svg/footerDraft.svg').default,
  publicForm: require('src/Framework/Common/Svg/publicForm.svg').default,
  formDraft: require('src/Framework/Common/Svg/formDraft.svg').default,
  library: require('src/Framework/Common/Svg/library.svg').default,
  information: require('src/Framework/Common/Svg/information.svg').default,
  headerFooter: require('src/Framework/Common/Svg/header-footer.svg').default,
  tag: require('src/Framework/Common/Svg/tag.svg').default,
  order: require('src/Framework/Common/Svg/order.svg').default,
  doubleArrowRight: require('src/Framework/Common/Svg/double-arrow-right.svg')
    .default,
  doubleArrowLeft: require('src/Framework/Common/Svg/double-arrow-left.svg')
    .default,
  recurrence: require('src/Framework/Common/Svg/recurrence.svg').default,
  scheduleBlock: require('src/Framework/Common/Svg/schedule-block.svg').default,
  conflict: require('src/Framework/Common/Svg/conflict.svg').default,
  clinicBuilding: require('src/Framework/Common/Svg/clinic-building.svg')
    .default,
  showAllCircle: require('src/Framework/Common/Svg/select-all-circle.svg')
    .default,
  showAllRound: require('src/Framework/Common/Svg/select-all-round.svg')
    .default,
  assignments: require('src/Framework/Common/Svg/assignments.svg').default,
  play: require('src/Framework/Common/Svg/play.svg').default,
  pause: require('src/Framework/Common/Svg/pause.svg').default,
  diploma: require('src/Framework/Common/Svg/diploma.svg').default,
  letterA: require('src/Framework/Common/Svg/letter-a.svg').default,
  view1: require('src/Framework/Common/Svg/view1.svg').default,
  view2: require('src/Framework/Common/Svg/view2.svg').default,
  undo: require('src/Framework/Common/Svg/undo.svg').default,
  redo: require('src/Framework/Common/Svg/redo.svg').default,
  arrowRight: require('src/Framework/Common/Svg/arrow-right.svg').default,
  phone: require('src/Framework/Common/Svg/phone.svg').default,
  download: require('src/Framework/Common/Svg/download.svg').default,
  filePreview: require('src/Framework/Common/Svg/file-preview.svg').default,
  privacyPolicy: require('src/Framework/Common/Svg/privacy-policy.svg').default,
  newLogoLetter: require('src/Framework/Common/Svg/new-logo-letter.svg')
    .default,
  newLogoWord: require('src/Framework/Common/Svg/new-logo-word.svg').default,
  newLogoBig: require('src/Framework/Common/Svg/new-logo-big.svg').default,
  newLogo: require('src/Framework/Common/Svg/new-logo.svg').default,
  newLogoSmall: require('src/Framework/Common/Svg/new-logo-small.svg').default,
  messages: require('src/Framework/Common/Svg/messages.svg').default,
  activity: require('src/Framework/Common/Svg/activity.svg').default,
  order2: require('src/Framework/Common/Svg/order2.svg').default,
  scpm: require('src/Framework/Common/Svg/scpm.svg').default,
  report: require('src/Framework/Common/Svg/report.svg').default,
  cats: require('src/Framework/Common/Svg/cats.svg').default,
  logout: require('src/Framework/Common/Svg/logout.svg').default,
  billing: require('src/Framework/Common/Svg/billing.svg').default,
  messageHistory: require('src/Framework/Common/Svg/message-history.svg')
    .default,
  fileContent: require('src/Framework/Common/Svg/file-content.svg').default,
  systemSetup: require('src/Framework/Common/Svg/systemSetup.svg').default,
  newTab: require('src/Framework/Common/Svg/new-tab.svg').default,
  formChecked: require('src/Framework/Common/Svg/formChecked.svg').default,
  key: require('src/Framework/Common/Svg/key.svg').default,
  chooseColumns: require('src/Framework/Common/Svg/chooseColumns.svg').default,
  type: require('src/Framework/Common/Svg/type.svg').default,
  darkTheme: require('src/Framework/Common/Svg/dark-mode.svg').default,
  type2: require('src/Framework/Common/Svg/type2.svg').default,
  referralType: require('src/Framework/Common/Svg/referral-type.svg').default,
  timer: require('src/Framework/Common/Svg/timer.svg').default,
  lab: require('src/Framework/Common/Svg/lab.svg').default,
  rx: require('src/Framework/Common/Svg/rx.svg').default,
  procedure: require('src/Framework/Common/Svg/procedure.svg').default,
  pointOfCare: require('src/Framework/Common/Svg/pointOfCare.svg').default,
  xray: require('src/Framework/Common/Svg/xray.svg').default,
  nurse: require('src/Framework/Common/Svg/nurse.svg').default,
  recall: require('src/Framework/Common/Svg/recall.svg').default,
  homework: require('src/Framework/Common/Svg/homework.svg').default,
  formAssigned: require('src/Framework/Common/Svg/form-assigned.svg').default,
  nurseOrders: require('src/Framework/Common/Svg/nurse-orders.svg').default,
  opacity: require('src/Framework/Common/Svg/opacity.svg').default,
  information2: require('src/Framework/Common/Svg/information2.svg').default,
  eventCreate: require('src/Framework/Common/Svg/event-create.svg').default,
  arrows: require('src/Framework/Common/Svg/arrows.svg').default,
  filter2: require('src/Framework/Common/Svg/filter2.svg').default,
  download2: require('src/Framework/Common/Svg/download2.svg').default,
  certificate: require('src/Framework/Common/Svg/certificate.svg').default,
  readOnly: require('src/Framework/Common/Svg/read-only.svg').default,
  referrals: require('src/Framework/Common/Svg/referrals.svg').default,
  education: require('src/Framework/Common/Svg/education.svg').default,
  reload: require('src/Framework/Common/Svg/reload.svg').default,
  save: require('src/Framework/Common/Svg/save.svg').default,
  collapseOpen: require('src/Framework/Common/Svg/collapseOpen.svg').default,
  collapseClose: require('src/Framework/Common/Svg/collapseClose.svg').default,
  noEdit: require('src/Framework/Common/Svg/no-edit.svg').default,
  eyeOnMonitor: require('src/Framework/Common/Svg/eye-on-monitor.svg').default,
  analytics: require('src/Framework/Common/Svg/analytics.svg').default,
  arrowDesc: require('src/Framework/Common/Svg/arrow-desc.svg').default,
  WoltersKluwer: require('src/Framework/Common/Svg/WoltersKluwer.svg').default,
  clockSolid: require('src/Framework/Common/Svg/clock-solid.svg').default,
  chart: require('src/Framework/Common/Svg/chart-646.svg').default,
  letterS: require('src/Framework/Common/Svg/letter-s.svg').default,
  accessability: require('src/Framework/Common/Svg/accessability.svg').default,
  asterix: require('src/Framework/Common/Svg/messaging/star.svg').default,
  favoriteViews: require('src/Framework/Common/Svg/favoriteViews.svg').default,
  results: require('src/Framework/Common/Svg/results.svg').default,
  restore: require('src/Framework/Common/Svg/trash-restore.svg').default,
  allergy: require('src/Framework/Common/Svg/allergy.svg').default,
  batch: require('src/Framework/Common/Svg/batch.svg').default,
  needsReading: require('src/Framework/Common/Svg/needsReading.svg').default,
  read: require('src/Framework/Common/Svg/read.svg').default,
  notResulted: require('src/Framework/Common/Svg/notResulted.svg').default
} as const;

export type TAriaPopup =
  | 'true'
  | 'menu'
  | 'listbox'
  | 'tree'
  | 'grid'
  | 'dialog';

export interface IIcon {
  /**
   * @param name - You can check this list to see all icons that we have in system {@link ICONS_LIST}
   */
  name: string;
  color?: string;
  hoverColor?: string;
  containerProps?: any;
  disableBackground?: boolean;
  tooltip?: string | React.ReactNode;
  size?: number;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => any;
  tooltipProps?: Partial<TooltipPropsWithTitle>;
  active?: boolean;
  isCompleted?: boolean;
  iconScale?: number;
  iconStyle?: any;
  containerBackground?: string;
  hoverIcon?: string;
  disabled?: boolean;
  containerClassName?: string;
  changeColorOnHover?: boolean;
  loading?: boolean;
  asyncOnClick?: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => Promise<any>;
  badge?: number;
  badgeColor?: string;
  id?: string;
  disableClick?: boolean;
  rotateIcon?: number;
  isButton?: boolean;
  label?: string;
  toggled?: boolean;
  haspopup?: TAriaPopup;
  controlsId?: string;
  isExpanded?: boolean;
  enableBorder?: boolean;
  badgeTextColor?: string;
  onLongPress?: () => any;
  useIconAsImage?: boolean;
}

const ButtonIcon = (props: IIcon) => {
  const {
    name,
    color,
    hoverColor,
    containerProps,
    disableBackground,
    tooltip,
    size,
    onClick,
    tooltipProps,
    active,
    isCompleted,
    iconScale,
    iconStyle,
    containerBackground,
    hoverIcon,
    disabled,
    containerClassName,
    changeColorOnHover,
    loading,
    asyncOnClick,
    badge,
    badgeColor,
    badgeTextColor,
    id,
    disableClick,
    rotateIcon,
    isButton = false,
    label,
    toggled,
    haspopup,
    controlsId,
    isExpanded,
    enableBorder,
    useIconAsImage
  } = props;
  const [innerLoader, setInnerLoader] = useState(false);
  const innerIconScale = iconScale ? iconScale : defaultIconScale;
  const sizeIcon = size ? size : defaultSize;
  const style: any = {
    width: sizeIcon / innerIconScale,
    height: sizeIcon / innerIconScale,
    ...iconStyle
  };
  if (rotateIcon) {
    style.transform = `rotate(${rotateIcon}deg)`;
  }
  if (color) {
    style.background = color;
  }
  const activeStyleContainer: any = {};
  if (active) {
    activeStyleContainer.backgroundColor = color;
    style.background = 'white';
  }
  const customBackground = containerBackground
    ? {
        backgroundColor: containerBackground
      }
    : {};
  const onKeyDownAccessibility = (e: React.KeyboardEvent) => {
    if (
      e.key === 'Enter' ||
      (e.code === 'Space' && (e.target as HTMLElement).tagName === 'DIV')
    ) {
      if (e.target) {
        e.preventDefault();
        e.stopPropagation();
        // @ts-ignore
        e.target.click();
      }
    }
  };

  const Button = () => {
    const longPressOnClickProps: any = {};
    if (onClick || asyncOnClick) {
      longPressOnClickProps.onClick = async (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>
      ) => {
        if (disableClick) return;
        if (onClick) {
          e.stopPropagation();
          onClick(e);
        }
        if (asyncOnClick) {
          if (innerLoader) return;
          setInnerLoader(true);
          e.stopPropagation();
          await asyncOnClick(e);
          setInnerLoader(false);
        }
      };
    }

    let divProps: any = {
      id
    };
    if (!props.onLongPress) {
      divProps = {
        ...divProps,
        ...longPressOnClickProps
      };
    }

    return (
      <LongPress
        onLongPress={props.onLongPress}
        onClick={longPressOnClickProps.onClick}
      >
        {(longPressData) => {
          return (
            <Main
              hoverColor={hoverColor}
              color={color}
              changeColorOnHover={innerLoader ? false : changeColorOnHover}
              role={isButton ? 'button' : undefined}
              aria-label={isButton && label ? label : undefined}
              aria-pressed={toggled}
              aria-haspopup={haspopup}
              aria-expanded={isExpanded}
              aria-controls={
                (!!haspopup || isExpanded !== undefined) && controlsId
                  ? controlsId
                  : undefined
              }
              tabIndex={disabled || isCompleted || !isButton ? -1 : 0}
              disableClick={disableClick}
              disabled={disabled}
              hoverIcon={hoverIcon}
              onKeyDown={(e) => {
                onKeyDownAccessibility(e);
              }}
              {...divProps}
              {...longPressData?.longPressEvents}
              {...containerProps}
              style={{
                width: sizeIcon,
                height: sizeIcon,
                ...containerProps.style
              }}
            >
              {badge !== undefined && (
                <Badge
                  style={{
                    backgroundColor: badgeColor ? badgeColor : '#FF2828',
                    color: badgeTextColor
                      ? badgeTextColor
                      : badgeColor
                      ? pickTextColorBasedOnBgColorSimple(badgeColor)
                      : 'white'
                  }}
                >
                  {badge}
                </Badge>
              )}

              <Container
                isCompleted={isCompleted}
                color={color}
                hoverColor={hoverColor}
                disableClick={disableClick}
                changeColorOnHover={innerLoader ? false : changeColorOnHover}
                className={cn({
                  'custom-icon-container': true,
                  disableBackground: disableBackground,
                  [`${containerClassName}`]: true,
                  enableBorder: enableBorder
                })}
                style={{
                  ...activeStyleContainer,
                  width: sizeIcon,
                  height: sizeIcon,
                  ...customBackground
                }}
              >
                {(loading || innerLoader) && <AbsoluteLoader />}
                {useIconAsImage ? (
                  <img width={style.width} height={style.height} src={name} />
                ) : (
                  <Icon className="icon" icon={name} style={style} />
                )}
              </Container>
            </Main>
          );
        }}
      </LongPress>
    );
  };
  return tooltip ? (
    <Tooltip title={tooltip} overlayStyle={{ zIndex: 10000 }} {...tooltipProps}>
      <div
        style={{
          width: sizeIcon,
          height: sizeIcon,
          ...containerProps.style
        }}
      >
        {Button()}
      </div>
    </Tooltip>
  ) : (
    Button()
  );
};

ButtonIcon.defaultProps = {
  containerProps: {
    style: {}
  },
  tooltipProps: {},
  active: false,
  isCompleted: false,
  iconStyle: {},
  color: globalStyleVariables.newBrandingPrimary200,
  changeColorOnHover: false
};

export default React.memo(ButtonIcon);
